import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [2,6];

export const dictionary = {
		"/(app)": [9,[2],[3]],
		"/(app)/admin/dashboard": [~14,[2,6],[3]],
		"/(app)/admin/edit/bibliografia": [19,[2,6],[3]],
		"/(app)/admin/edit/[category]/[slug]/exercicios": [17,[2,6],[3]],
		"/(app)/admin/edit/[category]/[slug]/extras/[page=integer]": [18,[2,6],[3]],
		"/(app)/admin/edit/[category]/[slug]/[page=integer]": [16,[2,6],[3]],
		"/(app)/admin/edit/[category]/[slug]/[links]/[page]": [15,[2,6],[3]],
		"/(app)/admin/preview/email/mjml": [~22,[2,6],[3]],
		"/(app)/admin/preview/email/mjml/[template]": [~23,[2,6],[3]],
		"/(app)/admin/preview/email/svelte": [~24,[2,6],[3]],
		"/(app)/admin/preview/email/svelte/[template]": [~25,[2,6],[3]],
		"/(app)/admin/preview/[category]/[slug]/extras/[page]": [21,[2,6],[3]],
		"/(app)/admin/preview/[category]/[slug]/[page]": [20,[2,6],[3]],
		"/(app)/assinar": [26,[2],[3]],
		"/(app)/bibliografia": [~27,[2],[3]],
		"/(app)/cadastrar": [28,[2],[3]],
		"/(app)/contato": [29,[2],[3]],
		"/(marketing)/desinscreva-se": [40,[8]],
		"/(app)/entrar": [30,[2],[3]],
		"/(app)/inscreva-se": [31,[2],[3]],
		"/(app)/minha-conta": [32,[2,7],[3]],
		"/(app)/minha-conta/afiliados": [33,[2,7],[3]],
		"/(app)/minha-conta/assinatura": [34,[2,7],[3]],
		"/(app)/pesquisar": [35,[2],[3]],
		"/(app)/politica-de-privacidade": [36,[2],[3]],
		"/(app)/redefinir-senha": [37,[2],[3]],
		"/(app)/sobre-o-afh": [38,[2],[3]],
		"/(app)/termos-de-servico": [39,[2],[3]],
		"/(app)/[category=category]/[slug=slug]/exercicios/[page=integer]": [12,[2,5],[3]],
		"/(app)/[category=category]/[slug=slug]/extras/[page=integer]": [13,[2],[3]],
		"/(app)/[category=category]/[slug=slug]/[page=integer]": [~11,[2,4],[3]],
		"/(app)/[...notFound]": [10,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';