import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENV } from '$env/static/public';

Sentry.init({
	dsn: 'https://47c33ff60f5342f19ee417aa76638420@o4504529324670976.ingest.sentry.io/4504529328078848',
	environment: PUBLIC_ENV,
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 1,
	integrations: [Sentry.replayIntegration()],
	beforeSend(event, hint) {
		if (hint.originalException instanceof Error) {
			if (hint.originalException.message.includes('No slot size for availableWidth=0')) {
				// We ignore crappy google error
				return null;
			}
		}

		// If it's not an error we want to ignore, return the event as normal
		return event;
	}
});

export const handleError =
	PUBLIC_ENV === 'development' ? console.error : Sentry.handleErrorWithSentry();
